import React from 'react';
import MappyV2 from '../components/MappyV2';

function Version2() {
  return (
    <div>
      <MappyV2 />
    </div>
  );
}

export default Version2;
