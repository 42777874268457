import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Select,
  } from 'antd';

// Language Switcher Component
function LanguageSwitcher() {
    const { i18n } = useTranslation();
  
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
  
    return (
      <div style={{ float: 'right' }}>
        <Select
          defaultValue={i18n.language}
          style={{ width: 120 }}
          onChange={changeLanguage}
          aria-label="Select Language"
        >
          <Select.Option value="en">English</Select.Option>
          <Select.Option value="de">Deutsch</Select.Option>
          <Select.Option value="bs">Bosanski</Select.Option>
        </Select>
      </div>
    );
  }

export default LanguageSwitcher;