import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import axios from 'axios';
import NotifyForm from '../components/notify/NotifyForm';
import NotifyInfo from '../components/notify/NotifyInfo';
import NotifySupport from '../components/notify/NotifySupport';

function Notify() {
  const [isInBosnia, setIsInBosnia] = useState(true);

  useEffect(() => {
    // Fetch user location
    const fetchLocation = async () => {
      try {
        const response = await axios.get('/geo.php');
        console.log('response: ', response);
        if (response.data && response.data.country !== 'BA') {
          setIsInBosnia(false);
        }
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    fetchLocation();
  }, []);

  return (
    <div>
        <NotifyInfo />

        <Row
            gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}
            >
            
            <Col xs={24} sm={24} lg={12} className="gutter-row">
                <NotifyForm />
            </Col>

            {!isInBosnia && (
                <Col xs={24} sm={24} lg={12} className="gutter-row">
                    <NotifySupport />
                </Col>
            )}
        </Row>
    </div>
  );
}

export default Notify;
