// src/pages/Home.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

function Home() {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center' }}>
      <Title>{t('home.welcome')}</Title>
      <Paragraph>{t('home.select_version')}</Paragraph>
      <Button type="primary" style={{ margin: '10px' }}>
        <Link to="/version1">{t('home.version1')}</Link>
      </Button>
      <Button type="primary" style={{ margin: '10px' }}>
        <Link to="/version2">{t('home.version2')}</Link>
      </Button>
    </div>
  );
}

export default Home;
