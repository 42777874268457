import React from 'react';
import MappyV1 from '../components/MappyV1';

function Version1() {
  return (
    <div>
      <MappyV1 />
    </div>
  );
}

export default Version1;
