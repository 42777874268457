import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './components/LanguageSwitcher';
import Home from './pages/Home';
import Version1 from './pages/Version1';
import Version2 from './pages/Version2';
import Notify from './pages/Notify';
import About from './pages/About';
import './App.css';

const { Header, Content } = Layout;

function App() {
  const { t } = useTranslation();

  const menuItems = [
    { label: <Link to="/">{t('menu.home', 'Home')}</Link>, key: 'home' },
    { label: <Link to="/version1">{t('menu.version1', 'Version 1')}</Link>, key: 'version1' },
    { label: <Link to="/version2">{t('menu.version2')}</Link>, key: 'version2' },
    { label: <Link to="/notify">{t('menu.notifyMe')}</Link>, key: 'notifyMe' },
    { label: <Link to="/about">{t('menu.about')}</Link>, key: 'about' },
  ];

  return (
    <Router>
      <Layout>
        <Header>
          <Menu theme="dark" mode="horizontal" items={menuItems} />
          <LanguageSwitcher />
        </Header>
        <Content style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/version1" element={<Version1 />} />
            <Route path="/version2" element={<Version2 />} />
            <Route path="/notify" element={<Notify />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
}

export default App;
