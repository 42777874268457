// src/hooks/useMappy.js
import { useState, useEffect, useRef, useMemo } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { message } from 'antd';
import { v4 as uuidv4 } from 'uuid';

function useMappy({ autoExtract = false }) {
  const [data, setData] = useState([]);
  const [headerRow, setHeaderRow] = useState(0);
  const [startRow, setStartRow] = useState(1);
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [isSortVisible, setIsSortVisible] = useState(false);
  const [sortColumn, setSortColumn] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const fileInputRef = useRef(null);

  const [addressValidation, setAddressValidation] = useState({});
  const [shortUrls, setShortUrls] = useState([]);

  // State to store the uploaded file name
  const [uploadedFileName, setUploadedFileName] = useState('');

  // Manage reordered addresses separately
  const [reorderedAddresses, setReorderedAddresses] = useState([]);

  // Zones state
  const [zones, setZones] = useState([]);

  // Validation state
  const [headerRowError, setHeaderRowError] = useState('');
  const [startRowError, setStartRowError] = useState('');

  // Loading states
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Effect to manage sort visibility
  useEffect(() => {
    setIsSortVisible(columnsOptions.length > 0 && selectedColumns.length > 0);
  }, [columnsOptions, selectedColumns]);

  // Effect to set columnsOptions based on headerRow and data
  useEffect(() => {
    if (data.length > 0 && headerRow !== null && headerRow !== undefined) {
      // Adjust for Excel's row numbering by subtracting 1
      const headerRowIndex = headerRow - 1;
      const maxColumns = data.reduce((max, row) => Math.max(max, row.length), 0);
      let headers = [];

      if (headerRowIndex >= 0 && headerRowIndex < data.length) {
        const headerData = data[headerRowIndex];
        headers = Array.from({ length: maxColumns }, (_, index) => {
          const headerValue = headerData[index];
          return {
            label: headerValue !== undefined && headerValue !== ''
              ? headerValue
              : `Column ${String.fromCharCode(65 + index)}`,
            value: String.fromCharCode(65 + index),
          };
        });
      } else {
        // No header row specified; use column letters
        headers = Array.from({ length: maxColumns }, (_, index) => ({
          label: `Column ${String.fromCharCode(65 + index)}`,
          value: String.fromCharCode(65 + index),
        }));
      }
      setColumnsOptions(headers);
    } else {
      // Reset columnsOptions and selectedColumns when headerRow is null
      setColumnsOptions([]);
      setSelectedColumns([]);
    }
  }, [data, headerRow]);

  // Effect to clear reorderedAddresses when all columns are deselected
  useEffect(() => {
    if (selectedColumns.length === 0) {
      setReorderedAddresses([]);
      setZones([]);
    }
  }, [selectedColumns]);

  // Parse selected columns for address extraction
  const addresses = useMemo(() => {
    if (!data || data.length === 0 || selectedColumns.length === 0) {
      return [];
    }
  
    // Adjust for Excel's row numbering by subtracting 1
    const startRowIndex = startRow - 1;
    let extractedAddresses = data.slice(startRowIndex).map((row) => {
      const address = selectedColumns
      .map((col) => {
        const colIndex = XLSX.utils.decode_col(col.trim().toUpperCase());
        return row[colIndex] !== undefined && row[colIndex] !== ''
          ? row[colIndex]
          : '';
      })
        .filter((val) => val !== '')
        .join(', ');
  
      return address ? { id: uuidv4(), row, address } : null;
    }).filter((item) => item !== null);

    // Sort and return addresses based on selected column
    if (sortColumn) {
      const colIndex = XLSX.utils.decode_col(sortColumn.trim().toUpperCase());
      extractedAddresses.sort((a, b) => {
        const aValue = a.row[colIndex] || '';
        const bValue = b.row[colIndex] || '';
        return sortOrder === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
    }

    return extractedAddresses;
  }, [data, selectedColumns, sortColumn, sortOrder, startRow]);

  const shortenUrl = async (longUrl) => {
    try {
      const response = await axios.get(
        `https://tinyurl.com/api-create.php?url=${encodeURIComponent(longUrl)}`
      );
      return response.data;
    } catch (error) {
      console.error('Error shortening URL:', error);
      return null;
    }
  };

  const resetData = () => {
    setData([]);
    setColumnsOptions([]);
    setSelectedColumns([]);
    setIsSortVisible(false);
    setSortColumn('');
    setSortOrder('asc');
    setHeaderRow(0);
    setStartRow(1);
    setAddressValidation({});
    setShortUrls([]);
    setReorderedAddresses([]);
    setZones([]);
    setUploadedFileName('');
    setHeaderRowError('');
    setStartRowError('');
  };

  const handleReset = () => {
    resetData();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFileChange = (file) => {
    resetData();
    setIsLoading(true);
    setUploadedFileName(file.name);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryStr = evt.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(sheetData);
      setIsLoading(false);
      message.success('File uploaded successfully.');
    };
    reader.readAsBinaryString(file);
  };

  const handleSortColumnChange = (value) => {
    setSortColumn(value);
  };

  const handleSortOrderChange = (value) => {
    setSortOrder(value);
  };

  const handleHeaderRowChange = (headerRow) => {
    setHeaderRow(headerRow);

    if (headerRow !== null && headerRow !== undefined) {
      setStartRow(headerRow + 1);
      message.info(`Start Data Row automatically set to ${headerRow + 1} because Header Row is ${headerRow}.`);

      // Validate headerRow
      if (headerRow < 0 || headerRow > data.length) {
        setHeaderRowError(`Header Row must be between 0 and ${data.length}.`);
      } else {
        setHeaderRowError('');
      }
    }

    setReorderedAddresses([]);
    setZones([]);
  };

  const handleStartRowChange = (value) => {
    if (value >= 1 && value >= headerRow) {
      setStartRow(value);
      setReorderedAddresses([]);
      setZones([]);

      // Validate startRow
      if (value < 1) {
        setStartRowError('Start Data Row must be at least 1.');
      } else if (headerRow !== null && value <= headerRow) {
        setStartRowError(`Start Data Row must be greater than Header Row (${headerRow}).`);
      } else if (value > data.length) {
        setStartRowError(`Start Data Row cannot exceed total rows (${data.length}).`);
      } else {
        setStartRowError('');
      }
    }
  };

  // Function to extract addresses and validate them (for Version 1)
  const extractAndValidateAddresses = async () => {
    // Set reorderedAddresses to the computed addresses
    setReorderedAddresses(addresses);
    // Validate addresses
    await validateAddresses(addresses); // Pass the latest addresses
  };

  // Handle row reordering via drag and drop
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(reorderedAddresses);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setReorderedAddresses(reorderedItems);
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    const updatedAddresses = [...reorderedAddresses];
    [updatedAddresses[index - 1], updatedAddresses[index]] = [
      updatedAddresses[index],
      updatedAddresses[index - 1],
    ];
    setReorderedAddresses(updatedAddresses);
  };

  const handleMoveDown = (index) => {
    if (index === reorderedAddresses.length - 1) return;
    const updatedAddresses = [...reorderedAddresses];
    [updatedAddresses[index + 1], updatedAddresses[index]] = [
      updatedAddresses[index],
      updatedAddresses[index + 1],
    ];
    setReorderedAddresses(updatedAddresses);
  };

  const handleRemoveAddress = (index) => {
    const updatedAddresses = reorderedAddresses.filter((_, idx) => idx !== index);
    setReorderedAddresses(updatedAddresses);
    setAddressValidation((prevValidation) => {
      const updatedValidation = { ...prevValidation };
      delete updatedValidation[reorderedAddresses[index].id];
      return updatedValidation;
    });
  };

  const handleSubmit = async () => {
    // Check if all addresses are validated and valid
    const invalidAddresses = reorderedAddresses.filter((item) => {
      const isValid = addressValidation[item.id];
      return isValid === false || isValid === undefined;
    });

    if (invalidAddresses.length > 0) {
      message.warning('Some addresses are invalid or not validated. Please validate all addresses before submitting.');
      return;
    }

    setIsSubmitting(true);

    const mergedAddresses = reorderedAddresses
      .map((item) => item.address)
      .filter(Boolean);
    if (mergedAddresses.length > 0) {
      const zonesSplit = splitAddressesIntoZones(mergedAddresses);

      if (zonesSplit.length === 1) {
        const zone = zonesSplit[0];
        const destination = zone[zone.length - 1];
        const waypoints = zone.slice(0, -1).join('|');
        const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          destination
        )}&waypoints=${encodeURIComponent(waypoints)}`;
        window.open(url, '_blank');
        setIsSubmitting(false);
        return;
      }

      const newZones = [];

      for (const [index, zone] of zonesSplit.entries()) {
        const destination = zone[zone.length - 1];
        const waypoints = zone.slice(0, -1).join('|');
        const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
          destination
        )}&waypoints=${encodeURIComponent(waypoints)}`;
        const shortLink = await shortenUrl(url);
        newZones.push({
          id: uuidv4(),
          zoneNumber: index + 1,
          addresses: zone,
          shortUrl: shortLink || url, // Fallback to original URL if shortening fails
        });
      }

      setZones(newZones);
      message.success('Zones created successfully.');
    }

    setIsSubmitting(false);
  };

  const splitAddressesIntoZones = (addresses) => {
    const zones = [];
    for (let i = 0; i < addresses.length; i += 10) {
      const zone = addresses.slice(i, i + 10);
      zones.push(zone);
    }
    return zones;
  };

  // Refactored validateAddresses to handle async properly
  const validateAddresses = async (addressesToValidate) => {
    if (!Array.isArray(addressesToValidate)) {
      console.error('validateAddresses expects an array.');
      return;
    }

    setIsValidating(true);
    setAddressValidation({});
    const validationPromises = addressesToValidate.map(async (addressObj) => {
      try {
        const response = await axios.post('/validate_address.php', { address: addressObj.address });
        const isValid = response.data.is_valid;
        setAddressValidation((prev) => ({
          ...prev,
          [addressObj.id]: isValid,
        }));
      } catch (error) {
        console.error(`Error validating address "${addressObj.address}":`, error);
        setAddressValidation((prev) => ({
          ...prev,
          [addressObj.id]: false,
        }));
      }
    });

    await Promise.all(validationPromises);
    setIsValidating(false);
    message.success('Address validation completed.');
  };

  // For Version 2: Automatically extract and update reordered addresses
  useEffect(() => {
    if (autoExtract && data.length > 0 && selectedColumns.length > 0) {
      setReorderedAddresses(addresses);
    }
  }, [autoExtract, data, selectedColumns, addresses]);

  return {
    data,
    headerRow,
    setHeaderRow,
    headerRowError,
    startRow,
    setStartRow,
    startRowError,
    columnsOptions,
    selectedColumns,
    setSelectedColumns,
    isSortVisible,
    sortColumn,
    sortOrder,
    fileInputRef,
    addresses,
    reorderedAddresses,
    setReorderedAddresses,
    addressValidation,
    shortUrls,
    zones,
    uploadedFileName,
    isLoading,
    isValidating,
    isSubmitting,
    handleReset,
    handleFileChange,
    handleSortColumnChange,
    handleSortOrderChange,
    handleHeaderRowChange,
    handleStartRowChange,
    extractAndValidateAddresses, // For Version 1
    validateAddresses,
    handleOnDragEnd,
    handleMoveUp,
    handleMoveDown,
    handleRemoveAddress,
    handleSubmit,
  };
}

export default useMappy;
