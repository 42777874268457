// src/serviceWorkerRegistration.js

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export function register(config) {
    if ('serviceWorker' in navigator) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Service worker won't work if PUBLIC_URL is on a different origin
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (process.env.NODE_ENV === 'production') {
          // Check if service worker can be found. If it can't reload the page.
          navigator.serviceWorker
            .register(swUrl)
            .then(registration => {
              // Listen for updates to the service worker.
              registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                  return;
                }
                installingWorker.onstatechange = () => {
                  if (installingWorker.state === 'installed') {
                    if (navigator.serviceWorker.controller) {
                      // New content is available; execute callback
                      if (config && config.onUpdate) {
                        config.onUpdate(registration);
                      }
                    } else {
                      // Content is cached for offline use; execute callback
                      if (config && config.onSuccess) {
                        config.onSuccess(registration);
                      }
                    }
                  }
                };
              };
            })
            .catch(error => {
              console.error('Error during service worker registration:', error);
            });
        } else {
          // In development, you might want to unregister the service worker
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
          });
        }
      });
    }
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  }
  