import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

function NotifySupport() {
    const { t } = useTranslation();

    return (
        <>
            <h3>{t('notifySupport.supportTitle')}</h3>

            <p>{t('notifySupport.supportDescription')}</p>

            <Button
                type="primary"
                href="https://paypal.me/acordiccom?country.x=BA&locale.x=en_US"
                target="_blank"
            >
                {t('notifySupport.supportButton')}
            </Button>

            <h3>{t('notifySupport.thankYouTitle')}</h3>
            <p>{t('notifySupport.thankYouMessage')}</p>
        </>
    );
}

export default NotifySupport;
