import React from 'react';
import { useTranslation } from 'react-i18next';

function NotifyInfo() {
  const { t } = useTranslation();

  return (
    <>
        <h1>{t('notifyInfo.notifyTitle')}</h1>
        <p>{t('notifyInfo.welcomeMessage')}</p>

        <h3>{t('notifyInfo.howCanWeHelp')}</h3>

        <ul className="space-between-list-items">
            <li>
                <strong>{t('notifyInfo.bugs')}</strong> {t('notifyInfo.bugsMessage')}
            </li>

            <li>
                <strong>{t('notifyInfo.featureRequests')}</strong> {t('notifyInfo.featureRequestsMessage')}
            </li>

            <li>
                <strong>{t('notifyInfo.generalInquiries')}</strong> {t('notifyInfo.generalInquiriesMessage')}
            </li>

            <li>
                <strong>{t('notifyInfo.supportWork')}</strong> {t('notifyInfo.supportWorkMessage')}
            </li>
        </ul>
    </>
  );
}

export default NotifyInfo;
