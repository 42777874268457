// src/pages/About.jsx
import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

function About() {
  const { t } = useTranslation();

  return (
    <div>
      <Typography>
        <Title level={1}>{t('about.title')}</Title>
        <Paragraph>{t('about.description')}</Paragraph>
        <Paragraph>{t('about.features')}</Paragraph>
        <Paragraph>{t('about.contact')}</Paragraph>
      </Typography>
    </div>
  );
}

export default About;
