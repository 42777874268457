// src/components/NotifyForm.jsx
import React from 'react';
import { Form, Input, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function NotifyForm() {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const formData = new URLSearchParams();
            for (const key in values) {
                formData.append(key, values[key]);
            }

            const response = await axios.post('/send_email.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            if (response.data.status === 'OK') {
                console.log('Response: ', response.data.message)
                message.success(t('notifyForm.successMessage'));
                form.resetFields();
            } else {
                console.error('Response:', response);
                message.error(response.data.message || t('notifyForm.errorMessage'));
            }
        } catch (error) {
            console.error('Error sending form:', error);
            message.error(t('notifyForm.errorMessage'));
        }
    };

    return (
        <>
            <h3>{t('notifyForm.formTitle')}</h3>
            <p>{t('notifyForm.formDescription')}</p>

            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="name"
                    label={t('notifyForm.nameLabel')}
                    rules={[{ required: true, message: t('notifyForm.nameError') }]}
                >
                    <Input placeholder={t('notifyForm.namePlaceholder')} />
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t('notifyForm.emailLabel')}
                    rules={[
                        { required: true, message: t('notifyForm.emailError') },
                        { type: 'email', message: t('notifyForm.emailInvalid') },
                    ]}
                >
                    <Input placeholder={t('notifyForm.emailPlaceholder')} />
                </Form.Item>
                <Form.Item
                    name="message"
                    label={t('notifyForm.messageLabel')}
                    rules={[{ required: true, message: t('notifyForm.messageError') }]}
                >
                    <Input.TextArea rows={4} placeholder={t('notifyForm.messagePlaceholder')} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t('notifyForm.submitButton')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default NotifyForm;
